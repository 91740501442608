
import { Vue, Options } from "vue-class-component";
@Options({
  emits: ["update:modelValue"],
  props: {
    successSttPieces: {
      type: Number,
      default: 0
    },
    errorSttPieces: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  }
})
export default class UpdateNotification extends Vue {
  closeNotification() {
    this.$emit("update:modelValue", false);
  }
}
